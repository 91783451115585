import { useEffect, useState, useMemo } from 'react'
import { Labels } from './Labels'
import { Button } from './components/Button'
import { useAlert } from './hooks/useAlert'

const CONFIRM_MSG =
  "Aucun label n'est sélectionné, êtes-vous sur de vouloir valider votre labellisation ?"

export const Main = () => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])

  const asyncAlert = useAlert()

  // Reset selected labels on alert change
  useEffect(() => {
    setSelectedLabels([])
  }, [asyncAlert.data?.alertKey])

  // Change URL on alert change
  useEffect(() => {
    if (asyncAlert.loading || asyncAlert.error || asyncAlert.data === null)
      return

    const { serial, date, id } = asyncAlert.data

    window.history.replaceState(
      null,
      '',
      `${window.location.origin}/${date}/${serial}/${id}`,
    )
  }, [asyncAlert])

  function tag(label: string) {
    const labelIndex = selectedLabels.findIndex((l) => l === label)
    if (labelIndex !== -1) {
      selectedLabels.splice(labelIndex, 1)
      setSelectedLabels([...selectedLabels])
    } else {
      setSelectedLabels([...selectedLabels, label])
    }
  }

  const askConfirmation = useMemo(
    () => selectedLabels.length === 0,
    [selectedLabels],
  )

  if (asyncAlert.loading)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Chargement...
      </div>
    )
  if (asyncAlert.data === null)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Pas d'alertes à labeliser
      </div>
    )

  const { saveLabels } = asyncAlert.data

  return (
    <div className="flex h-screen flex-col justify-between">
      <div className="relative flex flex-1">
        <img
          className="absolute inset-0 m-auto max-h-full"
          id="alertImg"
          alt="Téléchargement en cours..."
        ></img>
      </div>
      <Labels selectedLabels={selectedLabels} tag={tag} />
      <div className="flex flex-row items-center gap-4 px-4 py-2">
        <Button title="Voir la page d'aide">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/osoai/Page-d-aide-Rituels-b72f4280ab0e4a13a1faaf807c3067a4"
          >
            <b>?</b>
          </a>
        </Button>
        <Button
          title="Enregistrer et passer au suivant"
          //disabled={selectedLabels.length === 0}
          primary={true}
          className="flex-1"
          onClick={() => {
            if (askConfirmation && !window.confirm(CONFIRM_MSG)) return
            saveLabels(selectedLabels)
          }}
        >
          Valider
        </Button>
      </div>
    </div>
  )
}
