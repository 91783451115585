import {
  getAlertKey,
  extractDateSerialAlertID,
  alertSortIndex,
} from 'common/alert'
import { AlertLabels, Alert, Alerts } from 'common/types'
import {
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { dataState, loadingState } from 'shared/types/asyncState'
import { onError } from 'shared/utils/error'
import { auth, database } from '../firebase'
import { remove, set, update } from '../firebaseMethods'
import { getStorage, ref as refStorage, getDownloadURL } from 'firebase/storage'

async function getAlertsFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const dateSerialAlertID = extractDateSerialAlertID(urlParameter)

  if (dateSerialAlertID === undefined) return null

  const source = 'link'
  const why = 'unknown'
  const priority = 1
  const labelersTarget = 3
  const sortIndex = alertSortIndex(priority, 0)
  const serial = dateSerialAlertID.serial
  const date = dateSerialAlertID.date
  const id = dateSerialAlertID.alertID

  const key = getAlertKey(serial, id)

  const alert: Alert = {
    serial,
    date,
    id,
    source,
    why,
    priority,
    labelersTarget,
    sortIndex,
  }

  const alerts: Alerts = {}

  alerts[key] = alert

  return alerts
}

export const useAlert = () => {
  const userId = auth.currentUser?.uid ?? 'unknown'

  // undefined = no yet set
  // null = no valid data found in URL
  const [alertsFromURL, setAlertsFromURL] = useState<Alerts | null | undefined>(
    undefined,
  )

  const BATCH_SIZE = 10
  const [fetchSize, setFetchSize] = useState<number>(BATCH_SIZE)
  const [alerts, setAlerts] = useState<Alerts | null>(null)

  const [alertKey, setAlertKey] = useState<string | null>(null)

  const [startTime, setStartTime] = useState(0)

  // Retrieve alerts from database, expanding fetchSize as needed
  useEffect(() => {
    // Not set yet
    if (alertsFromURL === undefined) return

    if (alertsFromURL !== null) {
      setAlerts(alertsFromURL)
      return
    }

    const alertsQuery = query(
      ref(database, 'alerts'),
      orderByChild('sortIndex'),
      limitToLast(fetchSize),
    )

    const unsubscribe = onValue(
      alertsQuery,
      (snapshot) => {
        const alerts = snapshot.val() ?? ({} as Alerts)

        // Remove alerts already labeled by this user
        Object.entries<Alert>(alerts).forEach(([key, { labelerUids }]) => {
          if (labelerUids !== undefined && labelerUids.includes(userId))
            delete alerts[key]
        })

        if (
          Object.keys(alerts).length >= BATCH_SIZE ||
          snapshot.size < fetchSize
        ) {
          setAlerts(alerts)
        } else {
          setFetchSize(fetchSize + BATCH_SIZE)
        }
      },
      onError,
    )

    return unsubscribe
  }, [userId, fetchSize, alertsFromURL])

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      setAlertsFromURL(await getAlertsFromURL())
    }

    runEffect()
  }, [])

  const randomlyPickAlertToLabel = useCallback(() => {
    if (alerts === null) return

    // Prevent selecting the same alert, if alerts was not yet updated
    const alertKeys = Object.keys(alerts).filter((key) => key !== alertKey)

    const nbKeys = alertKeys.length
    if (nbKeys === 0) return

    const randomIndex = Math.floor(Math.random() * nbKeys)
    setAlertKey(alertKeys[randomIndex])
  }, [alerts, alertKey])

  // Check if there is an other alert available. At start and possibly after
  useEffect(() => {
    if (alertKey === null) randomlyPickAlertToLabel()
  }, [alertKey, randomlyPickAlertToLabel])

  useEffect(() => {
    const storage = getStorage()
    if (alerts === null || alertKey === null) {
      return
    }

    const alert = alerts[alertKey]

    if (alert === undefined) return

    const fileName = alert.id + '.png'
    getDownloadURL(refStorage(storage, fileName))
      .then((url) => {
        //inserted into an <img> element
        const img = document.getElementById('alertImg') as HTMLImageElement
        if (img) {
          img.setAttribute('src', url)
        }
      })
      .catch((error: Error) => {
        onError(error)
        const img = document.getElementById('alertImg') as HTMLImageElement
        if (img) {
          img.setAttribute('alt', "Erreur : Il n'y a pas d'image associée")
        }
      })
  }, [alerts, alertKey])

  // Re-init start time on each alert change
  useEffect(() => {
    setStartTime(Date.now())
  }, [alertKey])

  const saveLabels = useCallback(
    async (labels: string[]) => {
      if (alerts === null || alertKey === null)
        throw Error('saveLabels called with null alert')

      const {
        serial,
        date,
        id,
        source,
        why,
        labelerUids,
        priority,
        labelersTarget,
      } = alerts[alertKey]

      const key = getAlertKey(serial, id)

      const alertLabels: AlertLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: labels.sort((labelA, labelB) => {
          return labelA.localeCompare(labelB)
        }),
      }

      if (labelerUids === undefined) {
        // First labelisation of this alert, create labeledAlert
        const labeledAlert = {
          serial,
          date,
          id,
          source,
          why,
        }
        await update(`labeledAlerts/${key}`, labeledAlert)
      }

      await set(`labeledAlerts/${key}/labelers/${userId}`, alertLabels)

      // Last index reached
      const updatedLabelerUids = [...new Set([...(labelerUids ?? []), userId])]
      const labelersCount = updatedLabelerUids.length
      if (labelersCount >= labelersTarget) {
        // remove alert when it has reached target
        await remove(`alerts/${alertKey}`)
        await set(`alertsToBeExported/${alertKey}`, true as const)
      } else {
        // update labelers count and uids
        const sortIndex = alertSortIndex(priority, labelersCount)
        await update(`alerts/${alertKey}`, {
          labelerUids: updatedLabelerUids,
          sortIndex,
        })
      }
      // Trigger selection of a new alert
      if (alertsFromURL) {
        setAlertsFromURL(null)
        setAlerts(null)
      }
      setAlertKey(null)
    },
    [alerts, alertKey, startTime, userId, alertsFromURL],
  )

  type UseAlert = {
    alertKey: string
    serial: string
    date: string
    id: string
    saveLabels: typeof saveLabels
  } | null

  if (alerts === null) return loadingState()

  if (alertKey === null) return dataState<UseAlert>(null)

  const alert = alerts[alertKey]

  // May happen when alerts is updated after a save, and old key is not yet updated
  if (alert === undefined) return dataState<UseAlert>(null)

  return dataState<UseAlert>({
    alertKey,
    serial: alert.serial,
    date: alert.date,
    id: alert.id,
    saveLabels,
  })
}
